import resolveLoginDestination from 'data/bootstrap/actions/resolveLoginDestination';
import personSlice from 'data/person';
import { ALREADY_REGISTERED_DIALOG } from 'register/dialogs';
import Monolith from 'shared/api/monolith';
import { setToken } from 'shared/auth';
import { openDialog } from 'wiw/dialogs';
import { errorNotice } from 'wiw/notices';

import { createAsyncThunk } from '@reduxjs/toolkit';

// error codes to handle with a dialog instead of toast
const errorDialogs = {
  5183: ALREADY_REGISTERED_DIALOG, // already registered
};

export function messageForPasswordErrorCode(code) {
  switch (code) {
    case 'PASSWORD_MIN_LENGTH_VIOLATION':
      return 'Your password was too short. Passwords must be at least 10 characters long.';
    case 'PASSWORD_CONTAINS_EMAIL':
      return 'Your password cannot contain your email address.';
    case 'PASSWORD_LACKS_VARIATION':
      return 'Your password does not have enough unique characters in it. Try a password with more variety.';
    case 'PASSWORD_TOO_COMMON':
      return 'Your password is too common or easy to guess.';
    case 'PASSWORD_TOO_WEAK':
      return 'Too common. Try a phrase of three or more words.';
    default:
      return null;
  }
}

// Error handling for creating a person through the monolith, as one should not.
function messageForErrorMonolith(error) {
  const defaultError = 'There was an error creating your account.';

  function fail(e) {
    return defaultError;
  }
  try {
    switch (error.code) {
      case 'PASSWORD_MIN_LENGTH_VIOLATION':
        return 'Your password was too short. Passwords must be at least 10 characters long.';
      case 'PASSWORD_CONTAINS_EMAIL':
        return 'Your password cannot contain your email address.';
      case 'PASSWORD_LACK_VARIATION':
        return 'Your password does not have enough unique characters in it. Try a password with more variety.';
      case 'PASSWORD_TOO_WEAK':
      case 'PASSWORD_TOO_COMMON':
        return 'Your password is too common or easy to guess.';
    }
    switch (error.code) {
      case 5183: // Conflict
        return 'You are already registered. Please log in.';
      case 5200:
        return 'Please provide a first and last name.';
      default:
        return fail(error);
    }
  } catch (e) {
    return fail(e);
  }
}

function monolithResponseToLoginServiceResponse(response) {
  const login = response.login;

  return {
    // ignore avatar
    id: login.id,
    firstName: login.first_name,
    lastName: login.last_name,
    email: login.email,
    phone: login.phone_number,
    token: login.token,
  };
}


/**
 * Create a login for a person.
 *
 * @template [T=unknown]
 * @type {import('react-redux').AsyncThunk<T>}
 */
export default createAsyncThunk(
  'auth/register',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await Monolith.post('/login/register', {
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.email,
        phone_number: data.phone,
        password: data.password,
        agree: data.true,
        account_id: data.accountId,
      });

      setToken(response.data.login.token);
      dispatch(resolveLoginDestination());
      dispatch(personSlice.actions.receivePerson(monolithResponseToLoginServiceResponse(response.data)));
      return response.data;
    } catch (error) {
      if (error.code in errorDialogs) {
        if (!(Array.isArray(data.skipErrorCodeDialogs) && data.skipErrorCodeDialogs.includes(error.code))) {
          dispatch(openDialog(errorDialogs[error.code], { message: error.body?.error }));
        }
      } else {
        dispatch(errorNotice(messageForErrorMonolith(error)));
      }
      return rejectWithValue(error);
    }
  },
);
